import { Row, Col, Form, Input, Button } from 'antd';

const { TextArea } = Input;

const ContactUs = () => {
    const [form] = Form.useForm();
    return (
        <Row style={{ padding: '0 30px' }} id="contact" justify="space-around">
            <Col md={10} style={{ padding: '50px 0' }}>
                <Row>
                    <Col sm={24} className="location-header"><span>Get In Touch</span></Col>
                    <Col sm={24} style={{ padding: "0 10px 0 0" }}>
                        <Form
                            form={form}
                            onFinish={(values) => {
                                fetch("https://submit-form.com/YYAQJ7rB", {
                                    method: 'POST',
                                    headers: {
                                        "Content-Type": "application/json",
                                        Accept: "application/json",
                                    },
                                    body: JSON.stringify({ ...values, _redirect: false })
                                }).then(
                                    reponse => {
                                        alert("Your enquiry has been submitted!");
                                        form.resetFields();
                                    },
                                    reject => {
                                        alert("Failed to submit your enquiry!");
                                    }
                                )

                            }}>
                            <Row justify="space-between">
                                <Col xs={24} md={11}>
                                    <Row>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="name"
                                                rules={[{ required: true, message: 'Please input your Name!' }]}
                                            >
                                                <Input className="text-area text-input" placeholder="Your Name" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="email"
                                                rules={[{ required: true, message: 'Please input your Email Address!' },
                                                { type: 'email', message: 'Please input a valid Email Address!' }]}
                                            >
                                                <Input className="text-area text-input" placeholder="Email" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                name="subject"
                                                rules={[{ required: true, message: 'Please input a Subject!' }]}
                                            >
                                                <Input className="text-area text-input" placeholder="Subject" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        name="message"
                                        rules={[{ required: true, message: 'Please input your Message!' }]}
                                    >
                                        <TextArea rows={8} className="text-area" placeholder="Your Message" />
                                    </Form.Item>
                                </Col>
                                <Col sm={24}><Button className="send-button" type="primary" htmlType="submit">SEND</Button></Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Col>
            <Col md={10} style={{ padding: '50px 0' }}>
                <Row>
                    <Col sm={24} className="location-header"><span>Our Location</span></Col>
                    <Col sm={24}>
                        KniTT LLC<br />
                        999 Corporate Dr, Suite 100<br />
                        Ladera Ranch, CA 92694.
                    </Col>
                    <Col xs={24}>+1 (949) 357-5331</Col>
                    <Col xs={24} style={{ paddingTop: '20px' }}>allina@knittglobal.com</Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ContactUs