import React, { useState, useEffect } from 'react'
import { Layout, Row, Menu, Col } from 'antd'
import { MenuOutlined } from '@ant-design/icons';
import { useLocation } from "@reach/router"
import logo from '../allina-logo.svg'

const { Footer } = Layout

const MenuLayout = ({ items }) => {
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('/')
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [])
  return <Menu mode="horizontal"  overflowedIndicator={<MenuOutlined style={{color: '#4DB4BD', fontSize: '24px'}} />}>
    {items.map(item => <Menu.Item key={item.link}><span className={`menu-item ${currentPath === item.link ? 'ant-menu-item-selected' : ''} `}><a href={item.link}>{item.title}</a></span></Menu.Item>)}
  </Menu>
}

const AppFooter = () => {
  const menuItems = [
    { title: 'About Allina', link: '/about' },
    { title: 'Why Allina', link: '/why' },
    // { title: 'Team', link: '/contact' },
    { title: 'Solutions', link: '/solutions' },
    { title: 'Use Cases', link: '/use-cases' },
]
  return <Footer className='footer-container'>
    <Row style={{ height: '100%' }} className="footer" justify="space-between" gutter={[0, 10]}>
      <Col xs={20} md={3}>
        <a href='/'><img src={logo} style={{ width: '100px' }} /></a>
      </Col>
      <Col xs={20} md={12} lg={5}>
        <span>&copy; 2021 Allina. All Right Reserved</span>
      </Col>
      <Col xs={4} md={24} lg={16}>
        <MenuLayout items={menuItems} />
      </Col> 
    </Row>
  </Footer>
}

export default AppFooter