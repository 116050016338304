import './App.css'
import { Router } from '@reach/router'
import { Layout } from 'antd'
import LandingPage from './Pages/Landing'
import AppFooter from './Layout/Footer'
import AppHeader from './Layout/AppHeader'
import ContactPage from './Pages/ContactPage'
import UseCasesPage from './Pages/UseCasesPage'
import AboutPage from './Pages/AboutPage'
import WhyPage from './Pages/WhyPage'
import SolutionsPage from './Pages/SolutionPage'
import ModelsPage from './Pages/ModelsPage'
import ChatHover from './Pages/ChatHover'
import InvoicePage from './Pages/InvoicePage'
import InvoiceView from './Pages/InvoicePageView'
import ChatBotPublic from './Pages/ChatBotPublic';

const { Content } = Layout

function Landing() {
  return (
    <>
      <ChatHover />
      <Layout>
        <AppHeader />
        <Content>
          <LandingPage />
        </Content>
        <AppFooter />
      </Layout>
    </>
  )
}

function About() {
  return (
    <>
      <ChatHover />
      <Layout>
        <AppHeader />
        <Content>
          <AboutPage />
        </Content>
        <AppFooter />
      </Layout>
    </>
  )
}

function Why() {
  return (
    <>
      <ChatHover />
      <Layout>
        <AppHeader />
        <Content>
          <WhyPage />
        </Content>
        <AppFooter />
      </Layout>
    </>
  )
}

function Solutions() {
  return (
    <>
      <ChatHover />
      <Layout>
        <AppHeader />
        <Content>
          <SolutionsPage />
        </Content>
        <AppFooter />
      </Layout>
    </>
  )
}

const UseCases = () => (
  <>
    <ChatHover />
    <Layout>
      <AppHeader />
      <Content>
        <UseCasesPage />
      </Content>
      <AppFooter />
    </Layout>
  </>)

const Contact = () => (
  <>
    <ChatHover />
    <Layout>
      <AppHeader />
      <Content>
        <ContactPage />
      </Content>
      <AppFooter />
    </Layout>
  </>)

function Models() {
  return (
    <>
      <ChatHover />
      <Layout>
        <AppHeader />
        <Content>
          <ModelsPage />
        </Content>
        <AppFooter />
      </Layout>
    </>
  )
}

function Invoice() {
  return (
    <Layout>
      <AppHeader />
      <Content>
        <InvoicePage />
      </Content>
      {/* <AppFooter /> */}
    </Layout>
  )
}

const App = () => <Router>
  <Landing path="/" />
  <About path="/about" />
  <Why path="/why" />
  <Solutions path="/solutions" />
  <UseCases path="/use-cases" />
  <Contact path="/contact" />
  <Models path="/models" />
  <Invoice path="/invoice" />
  <ChatBotPublic path="/ChatBotPublic" />
  {/* <InvoiceView path="/invoiceview" /> */}
</Router>


export default App;
