import '../App.css'
import React, { useState, useEffect } from 'react'
import { Layout, Row, Menu, Col } from 'antd'
import { MenuOutlined } from '@ant-design/icons';
import { useLocation } from "@reach/router"
import logo from '../allina-logo.svg'

const { Header } = Layout

const MenuLayout = ({ items }) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState('/')
    useEffect(() => {
        setCurrentPath(location.pathname)
    }, [])
    return <Menu mode="horizontal" overflowedIndicator={<MenuOutlined style={{color: '#4DB4BD', fontSize: '24px'}} />}>
        {items.map(item => <Menu.Item key={item.link}><span className={`menu-item ${currentPath === item.link ? 'ant-menu-item-selected' : ''} `}><a href={item.link}>{item.title}</a></span></Menu.Item>)}
    </Menu>
}

const AppHeader = () => {
    const menuItems = [
        { title: 'About Allina', link: '/about' },
        { title: 'Why Allina', link: '/why' },
        // { title: 'Team', link: '/contact' },
        { title: 'Solutions', link: '/solutions' },
        { title: 'Use Cases', link: '/use-cases' },
    ]
    return (
        <Header className="header">
            <Row style={{ flexFlow: 'nowrap', height: '64px', rowGap: '0px', width: '100%' }} justify="space-between">
                <Col xs={20} lg={8}>
                    <a href='/'><img src={logo} style={{ height: '35px', marginLeft: '20px' }} /></a>
                </Col>
                <Col xs={4} lg={16}>
                    <MenuLayout items={menuItems} />
                </Col>
            </Row>
        </Header>)
}
export default AppHeader