import { Row, Col, Input, Button } from 'antd';
import Team_one from '../Team_01.jpg'
import Team_two from '../Team_02.jpg'
import ContactUs from '../Layout/ContactUs';


const ContactHeader = () => (
    <Row className="contact-container" justify="start">
        <Col md={8}></Col>
    </Row>
)

const Expert = () => (
    <Row style={{ backgroundColor: '#3B76D4', color: 'white', textAlign: 'center', padding: '10px 0' }} justify="center">
        <Col sm={24} className="intelligent-txt" style={{ color: 'white' }}>Let's connect with expert</Col>
    </Row>
)

const MapView = () => (
    <Row style={{ backgroundColor: 'white', color: 'white', textAlign: 'center' }} justify="center">
        <Col sm={24}>
            <div style={{ width: "100%" }}>
                <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=120%20Vantis,%20Suite%20300,%20Aliso%20Viejo,%20CA%2092656+(KniTT%20LLC)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
                {/* <iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=1%20Grafton%20Street,%20Dublin,%20Ireland+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe> */}
            </div>
        </Col>
    </Row>
)

const Team = () => (
    <Row style={{ backgroundColor: 'white', color: 'black', padding: '100px 0' }} justify="center">
        <Col sm={24} className="intelligent-txt" style={{ fontWeight: 'bold', textAlign: 'center', paddingBottom: '50px' }}>Meet our Team</Col>
        <Col sm={24}>
            <Row justify="center" gutter={[64, 16]} style={{ margin: 0 }}>
                <Col md={6}>
                    <Row>
                        <Col sm={24}><img src={Team_one} style={{ maxWidth: '100%' }} /></Col>
                        <Col sm={24} style={{ paddingTop: '10px', fontWeight: 'bold', color: '#0D253F', fontSize: '1.2rem' }}>Jhon Deo</Col>
                        <Col sm={24} style={{ fontWeight: 'bold', color: '#0D253F' }}>CEO</Col>
                        <Col sm={24}> <div style={{ borderTop: '#3B76D4 3px solid', width: '50%', height: '20px', marginTop: '20px' }}></div></Col>
                        <Col sm={24} style={{ color: '#0D253F' }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <Row>
                        <Col sm={24}><img src={Team_two} style={{ maxWidth: '100%' }} /></Col>
                        <Col sm={24} style={{ paddingTop: '10px', fontWeight: 'bold', color: '#0D253F', fontSize: '1.2rem' }}>Grace Deo</Col>
                        <Col sm={24} style={{ fontWeight: 'bold', color: '#0D253F' }}>General Director</Col>
                        <Col sm={24}> <div style={{ borderTop: '#3B76D4 3px solid', width: '50%', height: '20px', marginTop: '20px' }}></div></Col>
                        <Col sm={24} style={{ color: '#0D253F' }}>Lorem Ipsu  m is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    </Row>
)

const LandingPage = () => {
    return (
        <Row>
            <Col sm={24}><ContactHeader /></Col>
            <Col sm={24}><Expert /></Col>
            <Col sm={24}><MapView /></Col>
            <Col sm={24} className='contact-us'><ContactUs /></Col>
            {/* <Col sm={24}><Team /></Col> */}
        </Row>
    );
}

export default LandingPage;
