import React from 'react';
import { DirectLine, ConnectionStatus } from 'botframework-directlinejs';
import ReactWebChat from 'botframework-webchat';
import updateIn from 'simple-update-in';
import './ChatBot.css';
import '../Home.css';

export default class extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: '',
            userid: '',
            username: '',
            conversationId: '',
            directLine: {},
            view: false,
            feedBack: null,
            value: '',
            popupContent: '',
            storeValue: '',
            suggestions: [],
            suggestionCallback: '',
            suggestionTypedText: "",
            typingChecking: "false",
            webSpeechPonyfill: null
        };
        this.handleTokenGeneration = this.handleTokenGeneration.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSaveFeedback = this.handleSaveFeedback.bind(this);
        this.handleSuggestion = this.handleSuggestion.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleSuggestionClick = this.handleSuggestionClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.moveHighlight = this.moveHighlight.bind(this);
        this.getSuggestionHtml = this.getSuggestionHtml.bind(this);
        this.fetchSpeechPonyfill = this.fetchSpeechPonyfill.bind(this);
    }
    async componentDidMount() {
        try {
            // var accountInfo = authProvider.getAccountInfo();
            var UserId = '';
            var UserName = '';
            let search = window.location.search;
            let params = new URLSearchParams(search);
            if (params != null) {
                console.log("id--" + UserId + "//name--" + UserName);
                UserId = this.getParams("userid");
                UserName = this.getParams("username");
            }
            await this.handleTokenGeneration(UserName, UserId);
            await this.fetchSpeechPonyfill();
            const store =
                window.WebChat.createStore(
                    {},
                    ({ dispatch }) => next => action => {
                        this.state.directLine.connectionStatus$
                            .subscribe(connectionStatus => {
                                if (connectionStatus === ConnectionStatus.ExpiredToken) {
                                    console.log("expired");
                                }
                                if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
                                    dispatch({
                                        type: 'WEB_CHAT/SEND_EVENT',
                                        payload: {
                                            name: 'webchat/join',
                                            value: { language: window.navigator.userAgent }
                                        }
                                    });
                                }
                                if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
                                    var platform = this.getParams("platform") ? this.getParams("platform") : "web";
                                    var os = this.getParams("os") ? this.getParams("os") : "windows";
                                    action = updateIn(action, ['payload', 'activity', 'channelData', 'Platform'],
                                        () => platform);
                                    action = updateIn(action, ['payload', 'activity', 'channelData', 'OS'],
                                        () => os);
                                }
                                if (action.type === 'DIRECT_LINE/DISCONNECT_FULFILLED') {
                                    console.log("final" + connectionStatus);
                                    console.log("finalexpired" + ConnectionStatus.ExpiredToken);
                                    console.log("final");
                                    this.handleTokenGeneration();
                                }
                            });
                        return next(action);
                    }
                );
            //this.state.storeValue = store;
            this.setState({ storeValue: store });
        } catch (error) {
            console.log("error in fetching token");
            console.log(error);
        }

        this.state.directLine.activity$
            .filter(activity => activity.type === 'message')
            .subscribe(function (activity) {
                console.log(activity);
            }
            );
    }
    getParams = (param) => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        if (params.has(param))
            return params.get(param);
        else
            return null;
    }
    handleTokenGeneration = async (name, id) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: name, userid: id })
        };
        await fetch('https://knittglobal-allina-bot-api-public-dev.azurewebsites.net/api/TokenGeneration/GenerateToken', requestOptions)
            .then(response => response.json())
            .then(data => this.setState({
                token: data.categoryObject.token,
                conversationId: data.categoryObject.conversationId,
                view: true
            }));
        //const response = await fetch(`api/TokenGeneration/GetToken`);
        //const data = await response.json();
        //this.setState({ token: data.categoryObject.token, conversationId: data.categoryObject.conversationId });
        this.state.userid = id;
        this.state.username = name;
        this.state.directLine = new DirectLine({ token: this.state.token });
        //this.setState({ view: true });
        //this.setState({ typingChecking: "true" });
    };
    async handleSuggestion(val, store) {
        if (val === "") {
            await this.setState({
                suggestions: []
            });
        }
        else {
            var valuess = null;
            const response = await fetch(`api/Suggestion/GetAzureSearch?myparam1=${val}`);
            const data = await response.json();
            var values = ["Hello", "yes", "no", "exit", "Welcome", "Thank You", "Approve", "Apply leave", "Reject", "Absence Balance", "Leave Balance", "Upcoming Holidays", "Apply Comp-Off", "Approve Leave", "Raise Incident Tickets", "Project Allocation Info", "Reporting Manager Change", "Reporting Manager Approval", "Approve Isolve Tickets", "My Manager", "My Account Manager", "Generate Salary Certificate", "Isolve Ticket Status", "Internal Job Posting", "My Designation", "My Joining Date", "RM Approval", "RM Change", "Resource Allocation", "ESettlement Approval", "SO Approval", "Cash advance Approval", "Purchase Request Approval", "Referral status", "HR Ticket", "Platinum Support"];
            valuess = values.filter(s =>
                s.toLocaleLowerCase().startsWith(val.toLowerCase())
            );
            valuess = valuess.concat(data.az_search);
            if (document.getElementsByClassName("webchat__send-box-text-box__input")[0].value.length !== 0) {
                this.setState({
                    suggestions: valuess,
                    suggestionTypedText: val.toLowerCase()
                });
                this.setState({ suggestionCallback: store });
            }
            else {
                await this.setState({ suggestions: [] });
            }
        }
    }

    moveHighlight(event, direction) {
        event.preventDefault();
        const { highlightedIndex, suggestions } = this.state;
        if (!suggestions.length) return;
        let newIndex = (highlightedIndex + direction + suggestions.length) % suggestions.length;
        if (newIndex !== highlightedIndex) {
            this.setState({
                highlightedIndex: newIndex
            });
        }
    }

    keyDownHandlers = {
        ArrowDown(event) {
            this.moveHighlight(event, 1);
        },
        ArrowUp(event) {
            this.moveHighlight(event, -1);
        },
        Enter(event) {
            var userMessage = "";
            const { suggestions } = this.state;
            if (!suggestions.length) {
                return;
            }
            event.preventDefault();
            if (suggestions[this.state.highlightedIndex])
                userMessage = suggestions[this.state.highlightedIndex];
            else if (document.getElementsByClassName("webchat__send-box-text-box__input")[0].value.length !== 0)
                userMessage = document.getElementsByClassName("webchat__send-box-text-box__input")[0].value;
            else
                return;
            this.applySuggestion(userMessage);
        }
    }

    handleKeyDown(event) {
        if (this.keyDownHandlers[event.key])
            this.keyDownHandlers[event.key].call(this, event);
    }

    async handleSuggestionClick(event) {
        await this.applySuggestion(event.currentTarget.textContent);
    }

    async applySuggestion(newValue) {
        this.setState({ typingChecking: "true", suggestions: [], highlightedIndex: -1 });
        this.state.suggestionCallback.dispatch({
            type: 'WEB_CHAT/SEND_MESSAGE',
            payload: {
                text: newValue
            }
        });
        this.state.suggestionCallback.dispatch({
            type: 'WEB_CHAT/SET_SEND_BOX',
            payload: {
                text: ""
            }
        });
    }
    getSuggestionCss(index) {
        var HIGHLIGHTED_CSS = "HIGHLIGHTED_CSS";
        var SUGGESTION_CSS = "SUGGESTION_CSS";
        return index === this.state.highlightedIndex ? HIGHLIGHTED_CSS : SUGGESTION_CSS;
    }

    handleClose(elmnt) {
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt !== x[i]) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }

    getSuggestionHtml(suggestion) {
        const lowerCaseSuggestion = suggestion.toLowerCase();
        return {
            __html: lowerCaseSuggestion.includes(this.state.suggestionTypedText) ? lowerCaseSuggestion
                .replace(this.state.suggestionTypedText, `<b>${this.state.suggestionTypedText}</b>`) : lowerCaseSuggestion
        };
    }
    handleSaveFeedback(ans) {
        var userid = this.getParams("userid") ? this.getParams("userid") : "11111";
        var feedbackmsg = this.state.value;
        var feedbacktype = this.state.feedBack;
        var convId = this.state.conversationId;
        fetch('api/Feedback/SaveFeedback',
            {
                method: "POST",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Uid: userid, FeedbackMessage: feedbackmsg, Convid: convId, FeedbackType: feedbacktype })
            }).
            then(response => response.text())
            .then(data => {

                console.log(data.getResult);
            });

        this.setState({ value: '' });
    }
    feedback(ans) {
        this.setState({ feedBack: ans });

        if (ans === "Send") {
            this.handleSaveFeedback(ans);
        }
        else if (ans === "Yes") {
            this.setState({ popupContent: "How was your experience?" });
            // console.log(this.state.value)
        }
        else if (ans === "No") {
            this.setState({ popupContent: "What went wrong?" });
            // console.log(this.state.value)
        }
    }

    handleChange = (event) => {
        this.setState({ value: event.target.value });
    }

    styleOptions = {
        monospaceFont: 'Arial,Helvetica,sans-serif',
        primaryFont: 'Arial,Helvetica,sans-serif',

        bubbleBackground: '#fff',
        bubbleBorderColor: 'dimgray',
        bubbleBorderRadius: 8,
        bubbleFromUserBackground: 'dimgray',
        bubbleFromUserTextColor: '#fff',
        bubbleFromUserBorderRadius: 8,

        hideUploadButton: true
    }
    fetchSpeechPonyfill = () => {
        this.setState({
            webSpeechPonyfill: window.WebChat.createCognitiveServicesSpeechServicesPonyfillFactory({
                subscriptionKey: '47f7687c5de94dd0be0fa290bbd6a398', region: 'westus', textNormalization: 'lexical'
            })
        });
    }
    cardActionMiddleware = ({ dispatch }) => next => card => {
        const { displayText, text, type, value } = card.cardAction;
        if (type === 'openUrl') {
            window.open(value, '_blank', 'noopener noreferrer');
        }
        next(card);
    }
    render() {
        console.log(this.state.username);
        var userid = this.getParams("userid") ? this.getParams("userid") : this.state.userid;
        var username = this.getParams("username") ? this.getParams("username") : this.state.username;
        if (!this.state.view) {
            return <div />;
        } else {
            const filteredSuggestions = this.state.suggestions.filter(
                suggestion =>
                    suggestion.toLowerCase().indexOf(this.state.suggestionTypedText.toLowerCase()) > -1
            );
            return (
                <div className="react-container webchat" >
                    <div className="webchat-main">
                        <ReactWebChat directLine={this.state.directLine} webSocket={true} username={username} store={this.state.storeValue} sendTypingIndicator={true} styleOptions={this.styleOptions} cardActionMiddleware={this.cardActionMiddleware} webSpeechPonyfillFactory={this.state.webSpeechPonyfill} />
                    </div>
                    <footer className="chat-footer" />
                </div>
            );
        }
    }
}