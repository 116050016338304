import React, { useState } from 'react'
import { Row, Col } from 'antd'
import b2bLogo from '../b2b-icon.svg'
import mspLogo from '../msp-icon.svg'
import b2bImage from '../models_02.svg'
import mspImage from '../models_03.svg'
import Logo from '../allina-logo-icon.png'
import DreamEmployee from '../Layout/DreamEmployee'
import ContactUs from '../Layout/ContactUs'

const ModelHeader = () => (
    <Row className="model-container" justify="start" style={{ padding: '300px 0' }}>
        <Col md={2} style={{ display: 'flex' }}></Col>
        <Col md={10} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', padding: '0px 10px' }}>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3.3rem', paddingBottom: '10px', lineHeight: '3rem' }}>Engagement Models</div>
                <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '20px' }}></div>
                <div>
                    Are you seeking to automate processes in your organization? Are you a Managed Services partner enhancing operations for a client?
                    Not matter what you do, we automate it right, just for you... </div>
                <div style={{ fontSize: '1rem' }}>Learn more about it, <span style={{ color: '#4DB4BD' }}>Talk to us today</span></div>
                {/* <div style={{ display: 'flex', padding: "30px 0" }}><img src={playIcon} style={{ height: '40px' }} /></div> */}
            </div>
        </Col>
    </Row>
)

const ModelSplit = () => (
    <Row style={{ backgroundColor: '#3B76D4', padding: '50px 0' }}>
        <Col sm={24}>
            <div style={{ color: '#FFF', margin: '50px', fontSize: '1rem', textAlign: 'center' }}>
                <Row align="middle" justify="center">
                    <Col xs={20} md={7} style={{ padding: '20px 40px' }}>You have not chartered an automation roadmap yet.</Col>
                    <Col xs={20} md={7} className="engagement-middle" style={{ margin: '20px 20px', padding: '20px 20px' }}>You want to optimize the footprint of Automation products and frameworks you have already invested in</Col>
                    <Col xs={20} md={7} style={{ padding: '20px 40px' }}>You are a managed service entity that desires to augment processes for customers, innovate and enhance ROI</Col>
                </Row>
            </div>
        </Col>
        <Col sm={24} style={{ textAlign: 'center', fontSize: '1.5rem', padding: '20px' }}>No matter where you are in your journey, Allina comes and joins just along!</Col>
    </Row>
)

const B2BModel = () => (
    <Row style={{ margin: '50px 0' }} justify="center" align="middle">
        <Col lg={6} style={{textAlign: 'center'}}>
            <img src={mspImage} style={{width: '250px'}} />
        </Col>
        <Col lg={14} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', padding: '20px' }}>
                <div>
                    <div style={{ fontWeight: 'bold' }}><img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />Workflow (WF) Based Pricing</div>
                    <ul>
                        <li>Buy licenses and pay only for the workflows you wish to automate</li>
                        <li>With Allina, you can start small and build along the way after realizing your ROI </li>
                        <li>We will ensure that Allina integrates seamlessly with your core and enterprise systems. Being a cost-effective model, you only pay for the integrations you choose to go ahead with</li>
                    </ul>
                </div>
            </div>
        </Col>
    </Row>
)

const MSPModel = () => (
    <Row style={{ margin: '50px 0' }} justify="center" align="middle">
        <Col lg={6} style={{textAlign: 'center'}}>
            <img src={b2bImage} style={{width: '250px'}} />
        </Col>
        <Col lg={14} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', padding: '20px' }}>
                <div>
                    <div style={{ fontWeight: 'bold' }}><img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />Multi-Tenant Shared Model</div>
                    <ul>
                        <li>MSPs serve multiple customers and manage huge and diverse applications and technology landscapes – all at once. They deal with standard and customized applications along with OEM-based products and open-source technologies.</li>
                        <li>A multi-tenant shared model enables them to procure workflow-based licenses and leverage its efficiency across multiple clients. Hence, resulting in scalable growth and cost-effectiveness.</li>
                        <li>Tier Based Pricing Model: Here, an MSP can gain from discounted rates based on workflow tiers. For instance, Tier 1 with less than 50 workflows (WFs) may have a certain pricing. However, Tier 2 with 50-100 WFs may have a much-discounted pricing and the subsequent tiers of more than 100 WFs becomes further affordable.</li>
                        <li>Tier Based Integration – Less than 50 WFs may entitle the MSP to one integration included in the cost. With every upper Tier, the MSP gets more integrations built into the cost thereby enhancing ROI.</li>
                    </ul>
                </div>
                <div>
                    <div style={{ fontWeight: 'bold' }}><img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />Dedicated Tenant Model</div>
                    <ul>
                        <li>For MSPs who manage sensitive clients with large business and volumes, a dedicated tenant model is available at a slightly higher pricing.</li>
                    </ul>
                </div>
                <div style={{ fontSize: '1.1rem', color: '#4DB4BD' }}>Managed Services Providers can white-label and resell our platform as well! </div>
            </div>
        </Col>
    </Row>
)

const TwoModels = () => {
    const [b2bSelected, setB2BSelected] = useState(true)
    return (<Row style={{ backgroundColor: '#000', padding: '50px 0' }}>
        <Col xs={24}>
            <Row align="middle" justify="center">
                <Col xs={22} md={10} className={"b2b-container " + (b2bSelected ? 'selected-container' : '')} onClick={() => setB2BSelected(true)}>
                    <div className="b2b-inner-container" style={{ display: 'flex', flexDirection: 'row', margin: '20px 0' }}>
                        <div style={{ margin: 'auto' }}><img src={b2bLogo} style={{ width: '30px' }} /></div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                            <span style={{ fontSize: '1.1rem' }}>B2B Model</span>
                            <span>(Business to Business model)</span>
                        </div>
                    </div>
                </Col>
                <Col xs={22} md={10} className={"msp-container " + (!b2bSelected ? 'selected-container' : '')} onClick={() => setB2BSelected(false)}>
                    <div className="msp-inner-container" style={{ display: 'flex', flexDirection: 'row', margin: '20px 0', }}>
                        <div style={{ margin: 'auto' }}><img src={mspLogo} style={{ width: '30px' }} /></div>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                            <span style={{ fontSize: '1.1rem' }}>MSP Model</span>
                            <span>(Managed Service Providers)</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
        <Col xs={24}>
            {b2bSelected && <B2BModel />}
            {!b2bSelected && <MSPModel />}
        </Col>
    </Row>)
}

const ModelsPage = () => {
    return (
        <Row>
            <Col sm={24} ><ModelHeader /></Col>
            <Col sm={24} ><ModelSplit /></Col>
            <Col sm={24} ><TwoModels /></Col>
            <Col sm={24} ><DreamEmployee /></Col>
            <Col sm={24} className='contact-us'><ContactUs /></Col>
        </Row>
    );
}

export default ModelsPage;
