import { Row, Col } from 'antd';
import EngagementModels from '../Layout/EngagementModels';
import ContactUs from '../Layout/ContactUs'
import Logo from '../allina-logo-icon.png'

const AboutBanner = () => (
    <Row style={{ backgroundColor: 'white', color: 'black', textAlign: 'center', padding: '100px 20px' }} justify="center">
        <Col md={18} className="intelligent-txt" style={{ fontSize: '2rem', fontWeight: 'bold' }}>An ALL-IN-A (All in One) intelligent digital colleague or employee</Col>
        <Col md={18} className="intelligent-txt" style={{ fontSize: '2rem', fontWeight: 'bold' }}>who could make things stress-free for you.</Col>
    </Row>
)

const About = () => (
    <Row className="allina-container" justify="start">
        <Col md={2} style={{ display: 'flex' }}></Col>
        <Col md={8} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', padding: '20px' }}>
                <div>
                    <div style={{ fontWeight: 'bold' }}><img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />About Allina</div>
                    <div style={{ padding: '5px 0px', color: '#4DB4BD', }}>
                    When redundancy takes over, even your best employees lose motivation. In turn, it hinders their quest for innovation and excellence.
                    </div>
                    <div style={{ fontSize: '1.2rem', paddingBottom: '20px' }}>But Allina puts an end to all of that!</div>
                    {/* <div style={{ padding: '5px 0px', color: '#4DB4BD', }}>
                        Even the best employees lose motivation when redundancy takes over and hinders their quest for innovation and excellence.
                    </div> */}
                </div>
            </div>
        </Col>
        <Col md={10} style={{ display: 'flex' }}></Col>
    </Row>
)

const AboutIntro = () => (
    <Row className="about-container" justify="start" style={{ paddingTop: '80px' }}>
        <Col md={10} style={{ display: 'flex' }}></Col>
        <Col md={10} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto 40px', padding:'40px 0' }}>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem', paddingBottom: '10px', lineHeight: '3rem' }}>Instant. Intelligent. Immersive.</div>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem', paddingBottom: '10px', lineHeight: '3rem' }}>Personalized. Smart.</div>
                <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '20px' }}></div>
                <div style={{}}>This is the digital world we live in. This is ALLINA</div>
                <div style={{ display: 'flex' }}><div style={{ padding: '5px 0px', color: '#4DB4BD', }}>This is ALLINA</div></div>
                {/* <div style={{ display: 'flex', padding: "30px 0" }}><img src={playIcon} style={{ height: '40px' }} /></div> */}
            </div>
        </Col>
        <Col md={8}></Col>
    </Row>
)

const SplitContainer = () => (
    <Row className="split-container" justify="center">
        <Col md={24} className="two-phases" >Allina Comes To You In Two Phases</Col>
        <Col md={11} lg={8} className="swift-allina">
            <div style={{ fontFamily: 'Playfair Display', fontSize: '1.3rem', paddingBottom: '30px' }}>The Swift Allina</div>
            <div style={{ color: '#4DB4BD' , paddingBottom: '30px' }}>
                The Swift Allina will tirelessly work round the clock and manage everything in your organization that can be rule-based, redundant and repetitive.
            </div>
            <div style={{ color: '#4DB4BD' , paddingBottom: '30px' }}>
                Whether it is employee onboarding, payroll processing, BYOD management, invoice processing, payables automation, IT and network maintenance management, conference room allocations, or calendar management - Allina can do it all.
            </div>
            <div style={{ color: '#4DB4BD' , paddingBottom: '30px' }}>
                Wow! Isn't that a lot of employees rolled into one?
                Does that mean employees will become redundant? Definitely not!
                They can be their creative intelligent selves and contribute more to innovation and excellence.
                The result is the creation of a smarter and more intelligent organization.
                Does Allina stop there? No. In fact, that's where Allina just begins.
            </div>
        </Col>
        <Col md={11} lg={8} className="smart-allina">
            <div style={{ fontFamily: 'Playfair Display', fontSize: '1.3rem', paddingBottom: '30px' }}>The Smart Allina</div>
            <div style={{ color: '#4DB4BD' , paddingBottom: '30px' }}>
                Smart Allina is not only swift and seamless but also incredibly intelligent. 
                With each interaction across each process, Allina Learns.
                It understands context, emotion, and intent better and delivers the desired output to perfection!
            </div>
            <div style={{ color: '#4DB4BD' , paddingBottom: '30px' }}>
                Smart Allina brings about unmatched seamlessness accross the organization that enables employees to be at their best.
            </div>
            <div style={{ color: '#4DB4BD' , paddingBottom: '30px' }}>
            Organizations have worn the badge of man-years of experience proudly. Not anymore! It’s productivity that matters. It’s about getting the work done.
            </div>
        </Col>
    </Row>
)

const LandingPage = () => {
    return (
        <Row>
            <Col sm={24}><AboutIntro /></Col>
            <Col sm={24}><AboutBanner /></Col>
            <Col sm={24}><About /></Col>
            {/* <Col sm={24}><VideoContainer /></Col> */}
            <Col sm={24}><SplitContainer /></Col>
            <Col sm={24}><EngagementModels /></Col>
            <Col sm={24} className='contact-us'><ContactUs /></Col>
        </Row>
    );
}

export default LandingPage;
