import { Row, Col } from 'antd';
import ContactUs from '../Layout/ContactUs'
import DreamEmployee from '../Layout/DreamEmployee'
import Brain1 from '../why_01.png'

const BrainWave = () => (
    <Row style={{  }}>
        <Col md={24} className="brain-container" style={{ backgroundColor: 'black', display: 'flex',  }}>
            <div class="container">
                <img src={Brain1}></img>
                <div class="test2">
                    <span style={{ fontFamily: 'Playfair Display', fontSize: '2rem', color: 'white' }}>1. Allina understands your culture</span>
                    <span style={{ color: '#4DB4BD' }}>Allina will talk to your employees in the tone and conversational styles that you want it to.</span>
                    <span style={{ color: '#4DB4BD' }}>It will blend optimally with your employees making them feel at home. They will love Allina’s charm and adopt it as their favourite colleague.
                        Wondering how is that possible?</span>
                    <span style={{ color: '#4DB4BD' }}>Well, Allina is a conversationalist driven by empathy. It understands human emotions and responds accordingly. Allina is the Ms. Congeniality that your organization needs!</span>
                </div>
                <div class="test3">
                    <span style={{ fontFamily: 'Playfair Display', fontSize: '2rem', color: 'white' }}>2.  Allina adapts to who you are</span>
                    <span style={{ color: '#4DB4BD' }}>One of the best things about Allina is that it just integrates with who you are. You could be using myriad applications, systems, and underlying technology.</span>
                    <span style={{ color: '#4DB4BD' }}>Allina’s fluid APIs connect and integrate with your enterprise systems and enhance your ROI. What’s more is Allina understands business logic and human emotions equally well. That’s why it’s always ready to give you the perfect response. Day in and day out.</span>
                    <span style={{ color: '#4DB4BD' }}> Whether you have systems on-premises or on the cloud, whether you are an organization or a managed services provider, Allina is the intelligent employee designed to be one of you – one that can live your mission and follow your values with utmost passion and zeal!</span>
                </div>
                <div class="test1">
                    <span style={{ fontFamily: 'Playfair Display', fontSize: '2rem', color: 'white' }}>3. Allina is the 'Go-to' colleage you always wanted!</span>
                    <span style={{ color: '#4DB4BD' }}>Allina not only takes over anything that is rule-based, repetitive, and redundant, but also adds intelligence to it and becomes an independent entity. It requires no external support from any employee over time.</span>
                    <span style={{ color: '#4DB4BD' }}>In fact, you don’t need to be a programmer to integrate Allina into your processes.</span>
                    <span style={{ color: '#4DB4BD' }}>Any citizen employee (be it from Finance, Marketing, HR, Administration, Operations) can integrate Allina effortlessly. Building the automation framework is simple. Empower it with processes and business logic. There’s no need to code or configure Allina. Our ready templates and frameworks can help you automate your processes faster, innovate and test enhancements, and deploy augmented processes in virtually no time. </span>
                </div>
            </div>
        </Col>
    </Row>
)


const WhyIntro = () => (
    <Row className="why-container" justify="start" style={{ paddingTop: '80px' }}>
        <Col md={10} style={{ display: 'flex' }}></Col>
        <Col xs={24} md={10} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', marginLeft: '60px', paddingRight: '160px' }}>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem', paddingBottom: '10px', lineHeight: '3rem' }}>Why Allina?</div>
                <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '20px' }}></div>
                <div style={{}}>Understands your culture</div>
                <div style={{}}>Adapts to who you are!</div>
                <div style={{}}>'Go-To' colleague that you always wanted!</div>
                {/* <div style={{ display: 'flex', padding: "30px 0" }}><img src={playIcon} style={{ height: '40px' }} /></div> */}
            </div>
        </Col>
        <Col md={8}></Col>
    </Row>
)

const WhyPage = () => {
    return (
        <Row>
            <Col sm={24}><WhyIntro /></Col>
            <Col sm={24}><BrainWave /></Col>
            <Col sm={24}><DreamEmployee /></Col>
            <Col sm={24} className='contact-us'><ContactUs /></Col>
        </Row>
    );
}

export default WhyPage;
