import React from 'react';
import ChatIcon from '../chat_icon.png'
import Logo from '../allina-logo.svg'
import Persona from '../persona.png'


function BotClicked() {
    var botContainer = document.getElementsByClassName("bot-container")[0];
    botContainer.style.top = "100px";
}
function BotMinimized() {
    var botContainer = document.getElementsByClassName("bot-container")[0];
    botContainer.style.top = "-100%";
}
function getParams(param){
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.has(param))
        return params.get(param);
    else
        return null;
}

function getOS() {
    var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
    console.log("platform//"+window.navigator.platform);
    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    return os;
}


var userid = "dl_" + (getParams("userid") ? getParams("userid") : "Mahesh.CM@knittglobal.com");
var username = getParams("username") ? getParams("username") : "Mahesh";

var osN = getOS();

const ChatHover = () => <> <div style={{ height: '80px', width: '80px', position: 'fixed', zIndex: 2000, top: '50%', right: '0', marginTop: '-25px', marginRight: '3px' }}>
    <img onClick={BotClicked} src={ChatIcon} style={{ height: '100%', width: '100%' }} />
</div>

    <div className="bot-container">
        <header className="bot-header">
            <img src={Persona} width={32} />
            <img src={Logo} width={32} style={{ width: "80px" }} />
            <div href="#" className="bot-fly" onClick={BotMinimized}>&#8593;</div>
        </header>
        <iframe width="400" height="800" src={'./ChatBotPublic?userid=' + userid + '&username=' + username + '&platform=web' + '&os=' + osN} />
    </div>

</>

export default ChatHover