import { Row, Col } from 'antd';

const DemoBanner = () => (
    <Row style={{ backgroundColor: 'white', textAlign: 'center', padding: '100px 10px' }} justify="center">
        <Col md={18} className="intelligent-txt" style={{ fontSize: '3.3rem', color: '#031E32', fontWeight: 'bold' }}>Onboard Allina today.</Col>
        <Col md={18} className="intelligent-txt" style={{ fontSize: '3.3rem', color: '#031E32', fontWeight: 'bold' }}>Do not keep the dream employee waiting...</Col>
        <Col md={18} style={{ fontSize: '1rem', color: '#031E32' }}>Take a free tour right away!</Col>
        <Col xs={24} md={18} style={{ display: 'flex', justifyContent: 'center' }}>
            <a href="#contact" style={{ backgroundColor: '#4DB4BD', color: '#031E32', borderRadius: '20px', padding: '5px 10px', color: '#000', fontSize: '1.1rem', margin: '20px 0', fontWeight: 'bold' }}>Demo</a>
        </Col>
    </Row>
)

export default DemoBanner;