import { Row, Col } from 'antd';
import b2bLogo from '../b2b-icon.svg'
import mspLogo from '../msp-icon.svg'


const Engagement = () => (
    <Row style={{ backgroundColor: 'white', color: 'black', textAlign: 'center', padding: '100px 0' }} justify="center">
        <Col sm={24} className="intelligent-txt" style={{ fontWeight: 'bold', fontSize: '3.5rem' }}>Engagement Models</Col>
        <Col sm={24}>
            <div style={{ backgroundColor: '#031E32', color: '#FFF', borderRadius: '10px', margin: '50px' }}>
                <Row align="middle" justify="center">
                    <Col xs={20} md={7} style={{ padding: '40px 40px' }}>You have not chartered an automation roadmap yet</Col>
                    <Col xs={20} md={7} className="engagement-middle" style={{ padding: '20px 20px' }}>You want to optimize the footprint of Automation products and frameworks you have already invested in</Col>
                    <Col xs={20} md={7} style={{ padding: '40px 40px' }}>You are a managed service entity that desires to augment processes for customers, innovate and enhance RoI</Col>
                </Row>
            </div>
        </Col>
        <Col sm={24} style={{ fontSize: '1.3rem', paddingBottom: '10px' }}>No matter where you are in the journey, Allina comes and joins just along!</Col>
        <Col sm={24} style={{ color: '#3B76D4', fontSize: '1rem' }}><a href="/models">KNOW MORE</a></Col>
    </Row>
)

const Models = () => (
    <Row style={{ }}>
        <Col md={12} xs={24} style={{ backgroundColor: '#1F5E88', display: 'flex', paddingTop: '50px', paddingBottom: '25px' }}>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
                <div><img src={b2bLogo} style={{ height: '60px' }} /></div>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem' }}>B2B Model</div>
                <div style={{ fontSize: '1rem' }}>Business to Business</div>
                <a href="/models"><div style={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '5px 5px', color: '#3B76D4', fontSize: '1.1rem', margin: '25px' }}>KNOW MORE</div></a>
            </div>
        </Col>
        <Col md={12} xs={24} style={{ backgroundColor: '#06385D', display: 'flex', paddingTop: '50px', paddingBottom: '25px' }}>
            <div style={{ margin: 'auto', textAlign: 'center' }}>
                <div><img src={mspLogo} style={{ height: '60px' }} /></div>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem' }}>MSP Model</div>
                <div style={{ fontSize: '1rem' }}>Managed Service Providers</div>
                <a href="/models"><div style={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '5px 5px', color: '#3B76D4', fontSize: '1.1rem', margin: '25px' }}>KNOW MORE</div></a>
            </div>
        </Col>
    </Row>
)

export default () => (<>
    <Engagement />
    <Models />
</>)