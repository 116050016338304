import React, { useState } from 'react'
import { Row, Col } from 'antd';
import ContactUs from '../Layout/ContactUs'
import Incident from '../icon_Incident.png'
import BusinessApps from '../icon_business_apps.png'
import Proactive from '../icon_proactive.png'
import UserRequests from '../icon_user_requests.png'
import Logo from '../allina-logo-icon.png'
import Concept from '../conceptual_architecture_01.png'
import Concept2 from '../conceptual_architecture_02.png'
import Expense from '../expense_automation.png'

const Engagement = () => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    return (<Row style={{ backgroundColor: 'black', color: 'white', padding: '100px 30px' }} justify="center">
        <Col xs={24}>
            <Row align="middle" justify="center" style={{ margin: "50px 0" }}>
                <Col xs={22} md={5} className={"b2b-container " + (selectedIndex === 0 ? 'selected-container' : '')} onClick={() => setSelectedIndex(0)}>
                    <div className="b2b-inner-container" style={{ display: 'flex', flexDirection: 'row', margin: '20px 0', height: '80px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                            <span style={{ fontSize: '1.1rem' }}>IT Automation</span>
                        </div>
                    </div>
                </Col>
                <Col xs={22} md={5} className={"mid-container " + (selectedIndex === 1 ? 'selected-container' : '')} onClick={() => setSelectedIndex(1)}>
                    <div className="msp-inner-container" style={{ display: 'flex', flexDirection: 'row', margin: '20px 0', height: '80px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                            <span style={{ fontSize: '1.1rem' }}>Workplace Automation</span>
                        </div>
                    </div>
                </Col>
                <Col xs={22} md={5} className={"mid-container " + (selectedIndex === 2 ? 'selected-container' : '')} onClick={() => setSelectedIndex(2)}>
                    <div className="msp-inner-container" style={{ display: 'flex', flexDirection: 'row', margin: '20px 0', height: '80px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                            <span style={{ fontSize: '1.1rem' }}>Hiring Automation</span>
                        </div>
                    </div>
                </Col>
                <Col xs={22} md={5} className={"msp-container " + (selectedIndex === 3 ? 'selected-container' : '')} onClick={() => setSelectedIndex(3)}>
                    <div className="msp-inner-container" style={{ display: 'flex', flexDirection: 'row', margin: '20px 0', height: '80px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}>
                            <span style={{ fontSize: '1.1rem' }}>Expense Automation</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
        <Col sm={24}>
            {selectedIndex === 0 && <ITAutomation />}
            {selectedIndex === 1 && <WorkPlaceAutomation />}
            {selectedIndex === 2 && <HiringAutomation />}
            {selectedIndex === 3 && <ExpenseAutomation />}
        </Col>
    </Row>)
}

const ExpenseAutomation = () => (
    <Row justify="center">
        <Col sm={22} lg={16} style={{ padding: '30px 0' }}>
            <span>The amount, type and number of expenses, reimbursements, and invoices to be processed is a never-ending task. Being repetitive and rule-based, it can increase the chances of errors by tiring out the employee at work.</span>
        </Col>
        <Col sm={22} lg={16} style={{ color: '#4DB4BD', marginBottom: '30px' }}>
            <span>But that could be a thing of the past with our Expense Automation solutions. An employee just needs to scan the bill using their mobile phone and upload it. Our solution is efficient to read the line items, classify it as per expense heads, and export it to a CRM/ finance system for further processing and pay outs. How seamless! Isn’t it?</span>
        </Col>
        <Col sm={22} style={{ backgroundColor: '#191919', borderRadius: '15px', padding: '10px' }}>
            <Row>
                <Col sm={24} lg={12} style={{ padding: '10px' }}>
                    <div style={{ padding: "30px 0" }}>
                        <div style={{ fontWeight: 'bold' }}>
                            <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                            <span>How does it work?</span>
                        </div>
                        <div style={{ color: '#4DB4BD', }}>
                            <ul>
                                <li>An image is captured of the bill and provided to <span style={{ color: '#FFF', }}>Allina</span> Interface</li>
                                <li><span style={{ color: '#FFF', }}>Allina</span> uses Azure Computer Vision and other Computer Vision services to extract information from the bill</li>
                                <li>The bill also can be classified into pre-defined categories like transport, restaurant etc.</li>
                                <li>Information extracted includes: Establishment name, invoice date, invoice line items, invoice totals, etc.</li>
                                <li>The extracted information is provided to the user as text output and any interfacing systems can consume the JSON output of the same</li>
                            </ul>
                        </div>
                    </div>
                </Col>
                <Col sm={24} lg={12} style={{ padding: '10px' }}>
                    <div style={{ padding: "30px 0" }}>
                        <div style={{ fontWeight: 'bold' }}>
                            <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                            <span>Expense Automation</span>
                        </div>
                        <div style={{ color: '#4DB4BD', }}>
                            <ul>
                                <li>Enterprise Document Automation by ARIA using Computer Vision & Machine Learning</li>
                                <li>Fully automated Expense & Invoice management with zero paper work increasing compliance & employee productivity</li>
                                <li>Enterprise ready product with easy integration with HR, Accounting & Payroll</li>
                                <li>Rich reporting and Dashboard</li>
                            </ul>
                        </div>
                    </div>
                    <img src={Expense} style={{ objectFit: 'contain', width: '100%' }} />
                </Col>
            </Row>
        </Col>
        <Col sm={22} lg={16} style={{ marginTop: '30px' }}>
            <span>You can experience the same efficiency when it comes to processing invoices too. Your organization can manage payables smoothly and vendors can receive the payments for their services rendered well within time. With well-managed payables, organizations stand a chance to negotiate better with their vendors. They can also optimize value from the payments made to vendors for their services.</span>
        </Col>
        <Col sm={22} lg={16} style={{ padding: '30px 0', display: 'flex' }}>
            <div style={{ borderTop: '#4DB4BD 3px solid', width: '90px', height: '20px', margin: 'auto' }}></div>
        </Col>
        {/* <Col sm={22} style={{ fontFamily: 'Playfair Display', fontSize: '3rem', lineHeight: '3rem', color: '#FFF', fontWeight: 700, textAlign: 'center' }}>
            <span>Learn more about it, implement our expense solutions today,</span>
        </Col> */}
        <Col sm={22} style={{ paddingTop: '20px', fontSize: '1rem', textAlign: 'center' }}>
            <span>Enhance your operational efficiency by implementing our expense solutions today.</span>
        </Col>
        <Col sm={22} style={{ paddingTop: '20px', fontSize: '1rem', textAlign: 'center', display: 'flex' }}>
            <a href="/why" style={{ color: '#031E32', fontWeight: 'bold', borderRadius: '40px', padding: '10px 25px', backgroundColor: '#4DB4BD', fontSize: '1.1rem', margin: '5px', margin: 'auto', cursor: 'pointer' }}>
                <span>Here's how</span>
            </a>
        </Col>
    </Row>
)

const HiringAutomation = () => (
    <Row justify="center">
        <Col sm={22} lg={16} style={{ padding: '30px 0' }}>
            <span>One of the most important things that impacts an organization is its hiring function. If you get it right, it can work wonders! If you don’t, it can set the organization behind by a few years.</span>
        </Col>
        <Col sm={22} lg={16} style={{ color: '#4DB4BD', }}>
            <span>Our AI-powered automation engine can use historic data and patterns to ensure that the candidate chosen is the best fit for the role. It matches the resumes through custom indices and word graphs to recommend the best employees for your organization. </span>
        </Col>
        <Col sm={22} lg={16} style={{ padding: '30px 0' }}>
            <span>With our automation solutions, you can also address the other end of the spectrum – attrition. It can periodically throw up a list of employees who are at a high risk of attrition so that the organization can take immediate remedial measures as it sees fit. </span>
        </Col>
        <Col sm={22} lg={16} style={{ padding: '30px 0', display: 'flex' }}>
            <div style={{ borderTop: '#4DB4BD 3px solid', width: '90px', height: '20px', margin: 'auto' }}></div>
        </Col>
        <Col sm={22} style={{ fontFamily: 'Playfair Display', fontSize: '3rem', lineHeight: '3rem', color: '#FFF', fontWeight: 700, textAlign: 'center' }}>
            <span>Want to enhance your hiring and retention management?</span>
        </Col>
        <Col sm={22} style={{ paddingTop: '20px', fontSize: '1rem', textAlign: 'center' }}>
            <span>Our automation solutions might be just what you need. </span>
        </Col>
        <Col sm={22} style={{ paddingTop: '20px', fontSize: '1rem', textAlign: 'center', display: 'flex' }}>
            <a href="#contact" style={{ color: '#031E32', fontWeight: 'bold', borderRadius: '40px', padding: '10px 25px', backgroundColor: '#4DB4BD', fontSize: '1.1rem', margin: '5px', margin: 'auto', cursor: 'pointer' }}>
                <span>Reach out to us and explore our solutions</span>
            </a>
        </Col>

    </Row>
)

const WorkPlaceAutomation = () => {
    const [showWorkplaceOne, setShowWorkplaceOne] = useState(true)
    return (<Row justify="center">
        <Col sm={22} style={{ padding: '30px 60px' }}>
            <span>In our digitally driven economy, we have a lot of IT infrastructure and equipment that needs to be managed. For better safety, security and maintenance. Manually managing these permutations and combinations can be tedious and error-prone.</span>
        </Col>
        <Col sm={22} style={{ padding: '0 60px 30px 60px' }}>
            <span>What if you could use the power of cameras, computer vision, etc. to track movement of IT infrastructure? How about leveraging the power of digital and data to automate maintenance schedules and alert responsible teams to ensure automatic adherence to maintenance calendars? What about utilising the same systems to identify crowd hotspots, plan evacuation routes, and schedule and manage physical infrastructure allotment better?</span>
        </Col>
        <Col sm={22} style={{ backgroundColor: '#191919', borderRadius: '15px', padding: '10px' }}>
            <Row>
                <Col sm={24} style={{ margin: 'auto', textAlign: 'left', display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ color: '#FFF', borderRadius: '40px', padding: '3px 30px', backgroundColor: showWorkplaceOne ? '#3B76D4' : '#4D4D4D', fontSize: '1.1rem', margin: '15px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => setShowWorkplaceOne(true)}>
                        Workplace - 1
                    </div>
                    <div style={{ color: '#FFF', borderRadius: '40px', padding: '3px 30px', backgroundColor: !showWorkplaceOne ? '#3B76D4' : '#4D4D4D', fontSize: '1.1rem', margin: '15px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => setShowWorkplaceOne(false)}>
                        Workplace - 2
                    </div>
                </Col>
                {showWorkplaceOne && <WorkplaceOne />}
                {!showWorkplaceOne && <WorkplaceTwo />}
            </Row>
        </Col>
        <Col sm={22} style={{ paddingTop: '50px', fontSize: '1rem', textAlign: 'center' }}>
            <span>All this and much more can be achieved with our workplace automation solutions!</span>
        </Col>
        <Col sm={22} style={{ fontFamily: 'Playfair Display', fontSize: '3rem', lineHeight: '3rem', color: '#4DB4BD', fontWeight: 700, textAlign: 'center' }}>
            <span>Try them today.</span>
        </Col>
    </Row>)
}

const WorkplaceOne = () => (<>
    <Col sm={24} lg={12} style={{ padding: '10px' }}>
        <div style={{ padding: "30px 0" }}>
            <div style={{ fontWeight: 'bold' }}>
                <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                <span>What is it?</span>
            </div>
            <div style={{ color: '#4DB4BD', paddingLeft: '40px' }}>
                Track the workplace assets in real-time with IoT/Computer Vision
            </div>
        </div>
        <div style={{ padding: "30px 0" }}>
            <div style={{ fontWeight: 'bold' }}>
                <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                <span>How does it work?</span>
            </div>
            <div style={{ color: '#4DB4BD', }}>
                <ul>
                    <li>An IoT device (active) is placed on the asset which communicates the location and other features of the asset to the local network nodes.</li>
                    <li>This information is then provided to Azure IoT hub from where it is processed by <span style={{ color: '#FFF', }}>Allina</span> into floor maps and multiple Power BI reports</li>
                    <li>We can use computer vision instead of IoT devices depending on end user preference</li>
                </ul>
            </div>
        </div>
    </Col>
    <Col sm={24} lg={12} style={{ padding: '10px' }}>
        <div style={{ padding: "30px 0" }}>
            <div style={{ fontWeight: 'bold' }}>
                <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                <span>Conceptual Architecture</span>
            </div>
            <div style={{ color: '#4DB4BD', }}>
                <ul>
                    <li>It shows the IoT device attached to workplace furniture, their signals are captured on the LAN and set to an IoT hub, from here the data is further processed by Azure app services and Data Lake and various reports are generated.</li>
                </ul>
            </div>
        </div>
        <img src={Concept} style={{ objectFit: 'contain', width: '100%' }} />
    </Col>
</>)

const WorkplaceTwo = () => (<>
    <Col sm={24} lg={12} style={{ padding: '10px' }}>
        <div style={{ padding: "30px 0" }}>
            <div style={{ fontWeight: 'bold' }}>
                <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                <span>What is it?</span>
            </div>
            <div style={{ color: '#4DB4BD', paddingLeft: '40px' }}>
                Predictive ML Models by <span style={{ color: '#FFF', }}>Allina</span> can help you find the right candidate for an opening by scanning your database of resumes, it can also rank candidates to indicate their probability to join your firm.
            </div>
        </div>
        <div style={{ padding: "30px 0" }}>
            <div style={{ fontWeight: 'bold' }}>
                <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                <span>How does it work?</span>
            </div>
            <div style={{ color: '#4DB4BD', }}>
                <ul>
                    <li><span style={{ color: '#FFF', }}>Allina</span> performs a historic analysis of the hiring process</li>
                    <li><span style={{ color: '#FFF', }}>Allina</span> provides the prepared historical data to our model, train it and optimize it</li>
                    <li>The model is then run on currently available CVs and provides the ranking and probability of joining are ascertained</li>
                </ul>
            </div>
        </div>
    </Col>
    <Col sm={24} lg={12} style={{ padding: '10px' }}>
        <div style={{ padding: "30px 0" }}>
            <div style={{ fontWeight: 'bold' }}>
                <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                <span>Conceptual Architecture</span>
            </div>
        </div>
        <img src={Concept2} style={{ objectFit: 'contain', width: '90%' }} />
    </Col>
</>)

const ITAutomation = () => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    return (<Row justify="center">
        <Col md={10}>
            <Row>
                <Col sm={24}>
                    Allina not only takes over anything that is rule-based, repetitive, and redundant, but also adds intelligence to it and becomes an independent entity. It requires no external support from any employee over time.
                </Col>
                <Col sm={24} style={{ color: '#4DB4BD', paddingBottom: '20px' }}>
                    A simple task such as a password reset is frequently repeated, and extremely redundant. And yet it’s something that consumes valuable human time and effort.
                </Col>
                <Col sm={24} style={{ color: '#4DB4BD' }}>
                    How about automating it and many such processes? With over 350 use cases that leverage the power of digital technology, automation, and natural language processing, we have built capabilities to manage IT operations seamlessly.  With minimal human effort and time, we enable organizations to utilize their human capital more efficiently.
                </Col>
                <Col sm={24}><div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '40px', marginTop: '20px' }}></div></Col>
                {/* <Col sm={24}>
                    How about automating it and many such processes? With over 350 use cases that leverage the power of digital technology, automation, and natural language processing, we have built capabilities to manage IT operations seamlessly.  With minimal human effort and time, we enable organizations to utilize their human capital more efficiently.
                </Col> */}
                <Col sm={24} style={{ paddingTop: '50px', fontSize: '1rem' }}>
                    Want to know more?
                </Col>
                <Col sm={24} style={{ fontFamily: 'Playfair Display', fontSize: '3rem', lineHeight: '3rem', color: '#4DB4BD', fontWeight: 700 }}>
                    Talk to us now.
                </Col>
            </Row>
        </Col>
        <Col md={10}>
            <div style={{ backgroundColor: '#191919', borderRadius: '10px', margin: '20px', padding: '20px' }}>
                <div style={{ margin: 'auto', textAlign: 'left', display: 'flex', flexWrap: 'wrap' }}>
                    <div style={{ color: '#FFF', borderRadius: '40px', padding: '10px 25px', backgroundColor: selectedIndex === 0 ? '#3B76D4' : '#4D4D4D', fontSize: '1.1rem', margin: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => setSelectedIndex(0)}>
                        <img src={Incident} style={{ width: '25px', marginRight: '10px', objectFit: 'contain' }} />
                        Incident Resolution
                    </div>
                    <div style={{ backgroundColor: selectedIndex === 1 ? '#3B76D4' : '#4D4D4D', borderRadius: '40px', padding: '10px 25px', color: '#FFF', fontSize: '1.1rem', margin: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => setSelectedIndex(1)}>
                        <img src={UserRequests} style={{ width: '25px', marginRight: '10px', objectFit: 'contain' }} />
                        User Requests
                    </div>
                    <div style={{ backgroundColor: selectedIndex === 2 ? '#3B76D4' : '#4D4D4D', borderRadius: '40px', padding: '10px 25px', color: '#FFF', fontSize: '1.1rem', margin: '5px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => setSelectedIndex(2)}>
                        <img src={BusinessApps} style={{ width: '25px', marginRight: '10px', objectFit: 'contain' }} />
                        Business Apps Support
                    </div>
                    <div style={{ backgroundColor: selectedIndex === 3 ? '#3B76D4' : '#4D4D4D', borderRadius: '40px', padding: '10px 25px', color: '#FFF', fontSize: '1.1rem', margin: '5px', width: '280px', display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                        onClick={() => setSelectedIndex(3)}>
                        <img src={Proactive} style={{ width: '25px', marginRight: '10px', objectFit: 'contain' }} />
                        Proactive Maintenance
                    </div>
                </div>
                <div style={{ padding: '60px 30px' }}>
                    {selectedIndex === 0 && <IncidentResolution />}
                    {selectedIndex === 1 && <UserRequest />}
                    {selectedIndex === 2 && <BusinessApp />}
                    {selectedIndex === 3 && <ProactiveM />}
                </div>
            </div>
        </Col>
    </Row>)
}

const IncidentResolution = () => (<ul>
    <li>Monitoring System Alerts</li>
    <li>Level 1/Level 2 Helpdesk</li>
    <li>Level 3 Tech Support</li>
    <li>Password Reset/Unlock accounts</li>
    <li>Printer configurations</li>
</ul>)

const UserRequest = () => (<ul>
    <li>On-/off-boarding Employees</li>
    <li>Set-up Dev Environments</li>
    <li>Provisioning VM's (On-Prem/Cloud)</li>
    <li>Run SQL Queries and reports</li>
    <li>Modify VM's memory/disk space</li>
</ul>)

const BusinessApp = () => (<ul>
    <li>Monitor Business Application</li>
    <li>On-Demand Scaling</li>
    <li>Real-time Upgrades</li>
    <li>Cloud and On-Premises Application Integration</li>
</ul>)

const ProactiveM = () => (<ul>
    <li>Low Disk Space Remediation</li>
    <li>Monitor Application Log Files</li>
    <li>Create/Delete/Rename Snapshots</li>
    <li>Applications/Service/Process/Server Restarts</li>
</ul>)

const WhyIntro = () => (
    <Row className="solution-container" justify="start" style={{ paddingTop: '80px' }}>
        <Col md={10} style={{ display: 'flex' }}></Col>
        <Col md={10} style={{ display: 'flex' }}>
            <div style={{ margin: 'auto', marginLeft: '60px' }}>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3.3rem', paddingBottom: '10px', lineHeight: '3rem' }}>Solutions</div>
                <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '20px' }}></div>
                <div style={{}}>Allina offers unique solutions to create automated straight-through processes and build a lean, smart and agile organization.</div>
                <div style={{ fontSize: '1rem' }}>Want to know more?… <span style={{ color: '#4DB4BD' }}>Let’s talk!</span></div>
                {/* <div style={{ display: 'flex', padding: "30px 0" }}><img src={playIcon} style={{ height: '40px' }} /></div> */}
            </div>
        </Col>
        <Col md={8}></Col>
    </Row>
)


const SolutionsPage = () => {
    return (
        <Row>
            <Col sm={24}><WhyIntro /></Col>
            <Col sm={24}><Engagement /></Col>
            <Col sm={24} className='contact-us'><ContactUs /></Col>
        </Row>
    );
}

export default SolutionsPage;
