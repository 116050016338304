import { Row, Col } from 'antd'
import DreamEmployee from '../Layout/DreamEmployee'
import ContactUs from '../Layout/ContactUs'

const Sectors = () => (
    <Row style={{ backgroundColor: 'white', color: 'black', textAlign: 'center', padding: '100px 10px' }} justify="center">
        <Col sm={24} style={{ fontSize: '1.3rem' }}>We have looked at organizations holistically right from the top.</Col>
        <Col sm={24} style={{ fontSize: '1.3rem', paddingBottom: '10px' }}>Having spoken with the heads of various departments right from</Col>
        <Col sm={24} className="intelligent-txt" style={{ fontWeight: 'bold' }}>technology, automation, finance</Col>
        <Col sm={24} className="intelligent-txt" style={{ fontWeight: 'bold' }}>and people management.</Col>
        <Col sm={24} style={{ marginTop: '30px' }}>Extensively, we’ve created a huge repository of use cases so that you can build an incredibly smart and intelligent organization. Cut out the redundancy. Empower your human capital to make impactful contributions - both strategically and operationally.</Col>
    </Row>
)

const Models = () => (
    <Row style={{ backgroundColor: '#0C0F16' }} justify="center">
        <Col sm={24} style={{ display: 'flex', }}>
            <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem', margin: 'auto', padding: '50px', lineHeight: '3rem' }}>Key Automation Use Cases</div>
        </Col>
        <Col sm={24} lg={14} style={{ margin: '30px 0', padding: '0 5px' }}>
            {/* <div style={{ margin: 'auto', textAlign: 'center', display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ backgroundColor: '#FFF', borderRadius: '20px', padding: '5px 15px', color: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>End-user Self Service</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>End-user - Self Healing</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>L1 & ServiceDesk</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>Reliability Operations Center</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>Process Automations</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>Data Center and Cloud</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>Server and Storage Management</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>Citrix/Mobile Iron/Antivirus</div>
                <div style={{ color: '#FFF', borderRadius: '20px', padding: '5px 15px', backgroundColor: '#3B76D4', fontSize: '1.1rem', margin: '5px' }}>Data Backup</div>
            </div> */}
            {/* <div style={{ margin: 'auto', marginTop: '30px', display: 'flex', flexWrap: 'wrap', color: '#4DB4BD' }}>
                <ul>
                    <li>Application catalogue with SAM compliance</li>
                    <li>Account lock out management</li>
                    <li>Password reset by anytime, any device</li>
                    <li>SCCM based software automation</li>
                    <li>DL Management</li>
                    <li>Printer, network configurations</li>
                    <li>MFA management</li>
                    <li>VOIP troubleshooting</li>
                    <li>Smartphone management and registrations</li>
                    <li>BYOD onboarding and offboarding</li>
                    <li>Guest Wi-Fi access</li>
                    <li>IVR based password reset</li>
                    <li>IVR based hyper-personal support</li>
                    <li>Bots based helpdesk response</li>
                    <li>Bots based auto-ticketing</li>
                </ul>
            </div> */}
            <div style={{ margin: 'auto', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', color: '#4DB4BD', paddingBottom: '30px' }}>
                <ul style={{ minWidth: '250px' }}>
                    <li>End-user Self Service</li>
                    <li>End-user - Self Healing</li>
                    <li>L1 & ServiceDesk</li>
                    <li>Reliability Operations Center</li>
                    <li>Process Automations</li>
                </ul>
                <ul style={{ minWidth: '250px' }}>
                    <li>Data Center and Cloud</li>
                    <li>Server and Storage Management</li>
                    <li>Citrix/Mobile Iron/Antivirus</li>
                    <li>Data Backup</li>
                </ul>
            </div>
        </Col>
    </Row>
)

const LightBulb = () => (
    <Row className="idea-container" justify="start">
        <Col md={10} style={{ display: 'flex' }}></Col>
        <Col md={9} style={{ display: 'flex', height: '100%' }}>
            <div style={{ margin: 'auto', marginLeft: '60px' }}>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3.5rem', paddingBottom: '10px' }}>Use Cases</div>
                <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '10px' }}></div>
                <div style={{}}>Here's a list of some of our use cases.</div>
                <div style={{}}>Please reach us to learn more and see some of these in action.</div>
                <div style={{}}></div>
                {/* <div style={{ display: 'flex', padding: "30px 0" }}><img src={playIcon} style={{ height: '40px' }} /></div> */}
            </div>
        </Col>
        <Col md={8}></Col>
    </Row>
)

const UseCasesPage = () => {
    return (
        <Row>
            <Col sm={24}><LightBulb /></Col>
            <Col sm={24}><Sectors /></Col>
            <Col sm={24}><Models /></Col>
            <Col sm={24}><DreamEmployee /></Col>
            <Col sm={24} className='contact-us'><ContactUs /></Col>
        </Row>
    );
}

export default UseCasesPage;
