import React, { useState } from 'react';
import { Row, Col, Input, Button, Carousel } from 'antd';
import playIcon from '../play_icon_blue.svg'
import allinaDemo from '../landing_01.png'
import EngagementModels from '../Layout/EngagementModels'
import ContactUs from '../Layout/ContactUs'
import Thumbnail from '../allina screen.png'
import Logo from '../allina-logo-icon.png'
import Video1 from '../natural.mp4'
import Video2 from '../happy.mp4'
import Image1 from '../why_allina_01.png'
import Image2 from '../why_allina_02.png'
import Image3 from '../why_allina_03.png'
import Icon1 from '../web-allina-icon-01.svg'
import Icon2 from '../web-allina-icon-02.svg'
import Icon3 from '../web-allina-icon-03.svg'
import Preview from '../landing_04.png'
import SlideTwo from '../slide-2.jpg'
import SlideThree from '../slide-3.jpg'

const About = () => (
    <Row style={{ backgroundColor: 'white', color: 'black', textAlign: 'center', paddingTop: '100px', paddingLeft: '20px', paddingRight: '20px' }} justify="center">
        <Col sm={24} className="intelligent-txt">Instant. Intelligent. Immersive.</Col>
        <Col sm={24} className="intelligent-txt">Personalized. Smart.</Col>
        <Col sm={24}><div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', margin: 'auto', marginTop: '20px' }}></div></Col>
        <Col sm={24} style={{ fontSize: '1.3rem', paddingBottom: '10px' }}>This is the digital world we live in. Meet ALLINA.</Col>
        <Col sm={24}>An ALL-IN-A (All in One) intelligent digital colleague or employee who could make things stress-free for you.</Col>
        <Col sm={24}>When redundancy takes over, even your best employees lose motivation. In turn, it hinders their quest for innovation and excellence. But Allina puts an end to all of that!</Col>
        <Col xs={24} sm={24} style={{ padding: '20px 0', marginBottom: '70px' }}><a href="/about" style={{ color: '#3B76D4', fontSize: '1rem' }}>KNOW MORE</a></Col>
    </Row>
)

const GetAllina = () => (<Row className="get-allina" justify="start">
    <Col md={8} style={{ display: 'flex' }}><img src={allinaDemo} style={{ margin: 'auto', marginLeft: '0', width: '100%' }} /></Col>
    <Col md={8} style={{ display: 'flex' }}>
        <div style={{ margin: 'auto', marginLeft: '60px' }}>
            <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem', paddingBottom: '10px' }}>Get Allina today.</div>
            <div style={{ fontSize: '1.3rem' }}>Take a free tour right away!</div>
            <div style={{ display: 'flex' }}><a href="#contact" style={{ backgroundColor: '#4DB4BD', borderRadius: '20px', padding: '5px 10px', color: '#031E32', fontSize: '1.1rem', margin: '20px 0', fontWeight: 'bold' }}>Demo</a></div>
        </div>
    </Col>
    <Col md={8}></Col>
</Row>)

const Intro = () => {
    const [showVideoOne, setShowVideoOne] = useState(false)
    return (
        <Row className="into-container" justify="center" style={{ background: 'black' }}>
            {/* <Col sm={0} md={2} style={{ display: 'flex', height: '540px' }}></Col> */}
            <Col sm={24} lg={11} className="video-container" style={{ width: '100%', height: '540px' }}>
                {<video playsInline autoPlay muted loop poster={Thumbnail} id="bgvid" key="happy" style={{ width: '100%', height: '100%' }}>
                    <source src="https://allina-videos.s3.us-east-2.amazonaws.com/happy.mp4" type="video/mp4" />
                </video>}
                {/* {<video playsInline autoPlay muted loop poster="polina.jpg" id="bgvid" key='Video1' style={{ width: '100%', height: '100%' }}>
                    <source src={Video1} type="video/mp4" />
                </video>} */}
            </Col>
            <Col sm={24} lg={9} style={{ display: 'flex' }}>
                <div style={{ margin: 'auto 40px', padding: '40px 0' }}>
                    <div className="best-employee">Hired Your</div>
                    <div className="best-employee">Best Employee Yet?</div>
                    <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '20px' }}></div>
                    <div style={{}}>In today's age, the employee is a near-human digital asssistant. It accelerates process and revenue, enhances operational efficiency and works 24*7*365 at the same cost.</div>
                    <div style={{ display: 'flex' }}><a href="/about" style={{ padding: '5px 0px', color: '#4DB4BD', }}>How is that?</a></div>
                    {/* <div style={{ display: 'flex', padding: "30px 0" }}><img src={playIcon} style={{ height: '40px' }} /></div> */}
                </div>
            </Col>
            {/* <Col sm={0} md={2} style={{ display: 'flex', height: '540px' }}></Col> */}
        </Row>
    )
}

const CarouselSection = () => (
    <Row style={{ background: '#11141B' }}>
        <Col xs={24}>
            <Carousel dotPosition="top" adaptiveHeight autoplay>
                <div><WhyAllina /></div>
                <div><Experience /></div>
                <div><Intelligent /></div>
            </Carousel>
        </Col>
    </Row>
)

const Intelligent = () => (
    <Row justify="end" className="intelligent-container" style={{ padding: '150px 20px', color: '#FFF' }}>
        <Col xs={24} md={24} lg={12}>
            <Row>
                <Col xs={24} style={{ width: '100%', fontFamily: "Playfair Display", fontSize: '3rem', paddingLeft: '20px', paddingRight: '20px' }}>
                    Intelligent Digital Assistants Build SMART Organizations. Are You Interested?
                </Col>
                <Col xs={24}>
                    <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '20px', marginLeft: '20px' }}></div>
                </Col>
                <Col xs={24} style={{ color: '#4DB4BD', paddingLeft: '20px', paddingRight: '20px' }}>
                    Allina is cognitive, intelligent, process oriented, and relentless. It relishes redundancy and converts interactions into intelligence. The more she works, the smarter she becomes.
                    Create a SMART organization.  <a href="/about" style={{ color: 'white' }}>Today.</a>
                </Col>
            </Row>
        </Col>
    </Row>
)

const Experience = () => (
    <Row justify="end" className="experience-container" style={{ padding: '150px 20px', color: '#FFF' }}>
        <Col xs={24} md={24} lg={12}>
            <Row>
                <Col xs={24} style={{ width: '100%', fontFamily: "Playfair Display", fontSize: '3rem', paddingLeft: '20px', paddingRight: '20px' }}>
                    Man-Years of Experience is Passé. How About Productivity Per Employee?
                </Col>
                <Col xs={24}>
                    <div style={{ borderTop: '#4DB4BD 3px solid', width: '60px', height: '20px', marginTop: '20px', marginLeft: '20px' }}></div>
                </Col>
                <Col xs={24} style={{ color: '#4DB4BD', paddingLeft: '20px', paddingRight: '20px' }}>
                    Organizations have worn the badge of man-years of experience proudly. Not anymore! It’s productivity that matters. It’s about getting the work done. <a href="/solutions" style={{ color: 'white' }}>Learn More</a>
                </Col>
            </Row>
        </Col>
    </Row>
)

const WhyAllina = () => (
    <Row justify="center" style={{ background: '#11141B', paddingTop: '50px', paddingBottom: '50px', color: '#FFF' }}>
        <Col xs={24} md={24} lg={12}>
            <Row style={{ width: '100%', display: 'flex' }} justify="center">
                <Col xs={24} md={8} style={{ display: 'flex', padding: '0 20px 10px' }}>
                    <img src={Image2} style={{ height: '180px', margin: 'auto 0', objectFit: 'contain' }} />
                </Col>
                <Col xs={24} md={8} style={{ display: 'flex', flexDirection: 'column' }}>
                    <img src={Image1} style={{ height: '250px', paddingBottom: '20px', objectFit: 'contain' }} />
                    <img src={Image3} style={{ height: '180px', objectFit: 'contain' }} />
                </Col>
            </Row>
        </Col>
        <Col xs={24} md={24} lg={12}>
            <Row style={{ padding: '20px' }}>
                <Col xs={24} style={{ fontWeight: 'bold', display: 'flex' }}>
                    <img src={Logo} style={{ width: '20px', margin: 'auto 10px', objectFit: 'contain' }} />
                    <span>Why Allina</span>
                </Col>
                <Col xs={24} style={{ width: '100%', fontFamily: "Playfair Display", fontSize: '3.5rem', paddingLeft: '20px', paddingRight: '20px' }}>
                    Allina Understands Your Culture
                </Col>
                <Col xs={24} style={{ color: '#4DB4BD', paddingLeft: '20px', paddingRight: '20px' }}>
                    Allina will talk to your employees in the tone and conversational styles that you want it to. It will blend optimally with your employees making them feel at home. They will love Allina’s charm and adopt it as their favourite colleague.<a href="/why" style={{ color: 'white' }}>KNOW MORE</a>
                </Col>
                <Col xs={24} md={8} lg={8} className="icon-txt-container">
                    <img src={Icon1} style={{ height: '30px', marginBottom: '10px' }} />
                    Multiple Languages
                </Col>
                <Col xs={24} md={8} lg={8} className="icon-txt-container">
                    <img src={Icon2} style={{ height: '30px', marginBottom: '10px' }} />
                    Learn Business Logic
                </Col>
                <Col xs={24} md={8} lg={8} className="icon-txt-container">
                    <img src={Icon3} style={{ height: '30px', marginBottom: '10px' }} />
                    Contextual Understanding
                </Col>
            </Row>
        </Col>
    </Row>
)

const Solutions = () => (<>
    <Row justify="center" style={{ background: 'black', marginTop: '0px' }}>
        <Col xs={24} md={20} style={{ display: 'flex', flexDirection: 'column', padding: '40px' }}>
            <div style={{ fontFamily: 'Playfair Display', fontSize: '3.3rem', paddingBottom: '10px', fontWeight: 700 }}> Solutions</div>
            <div style={{ display: 'flex' }}>
                {/* <div style={{ fontSize: '1.6rem', color: '#4DB4BD', width: '50%', display: 'flex' }}>
                    <span>With Allina</span>
                    <img src={Preview} style={{ height: '300px', marginTop: '150px' }} />
                </div> */}
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {/* <div style={{ fontSize: '1.2rem', color: '#FFF' }}>Did you know,</div> */}
                    <div style={{ color: '#4DB4BD' }}>Organizations have worn the badge of man-years of experience proudly. Not anymore! It’s productivity that matters. It’s about getting the work done.</div>
                    <div style={{ fontSize: '1rem', paddingTop: '20px', color: '#FFF' }}>
                        <ul>
                            <li><a href="/solutions" className="sol-link">IT Operations Automation</a></li>
                            <li><a href="/solutions" className="sol-link">Workplace Automation</a></li>
                            <li><a href="/solutions" className="sol-link">Hiring Automation</a></li>
                            <li><a href="/solutions" className="sol-link">Expense Automation</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </Col>
    </Row>
    <Row className="bot-container" justify="end">
        <Col xs={24} md={12} style={{ display: 'flex' }}>
            <div style={{ display: 'flex', margin: 'auto 40px', padding: '40px 0', flexDirection: 'column' }}>
                <div style={{ fontFamily: 'Playfair Display', fontSize: '3rem', paddingBottom: '10px', fontWeight: 700 }}>Let's connect with expert</div>
                <div style={{ borderTop: '#FFF 3px solid', width: '80px', height: '20px', marginTop: '20px' }}></div>
                <span>Found Allina exciting and relevant?</span>
                <span>Know more about how it works. Connect with our expert today.</span>
                <div style={{ display: 'flex' }}>
                    <a href="#contact" style={{ backgroundColor: '#FFF', borderRadius: '30px', padding: '10px 20px', color: '#3B76D4', fontSize: '1.1rem', margin: '25px', fontWeight: 'bold' }}>CONTACT</a>
                </div>
            </div>
        </Col>

    </Row>
</>
)

const LandingPage = () => {
    return (
        <Row>
            <Col sm={24}><Intro /></Col>
            <Col sm={24}><About /></Col>
            <Col sm={24}><CarouselSection /></Col>
            <Col sm={24}><GetAllina /></Col>
            <Col sm={24}><Solutions /></Col>
            <Col sm={24}><EngagementModels /></Col>
            <Col sm={24} className='contact-us'><ContactUs /></Col>
        </Row>
    );
}

export default LandingPage;
